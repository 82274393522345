@use '../../utilities/variables' as v;

.table-container{
    // TABS
    .tabs{
        display: flex;
        flex-wrap: wrap;
        padding-top: 12px;
        padding-bottom: 32px;
        .tab{
            cursor: pointer;
            padding: 20px;
            // padding-top: 0;
            border-bottom: 1px solid v.$dark_grey;
            color: v.$dark_grey;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            &.active{
                border-bottom-color: v.$black;
                color: v.$black;
                font-weight: 600; 
            }
        }
    }
    .tab-contents{
        .tab-content{
            display: none;
            &.active{
                display: flex;
                flex-direction: column;
                gap: 32px;
            }
        }
    }
// Accordion
    .accordion{
        background-color: v.$white;
        padding: 32px;
        border-radius: 16px;
        .accordion-header{
            display: flex;
            justify-content: space-between;
            gap: 8px;
            align-items: center;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #0E0E0E;

            cursor: pointer;
            span{
                display: block;
                transition: transform .2s linear;
                height: 32px;
                content: url('../../utilities/icons/arrowdown-dark.svg');
            }
        }
        .accordion-body{
            display: none;
            flex-direction: column;
            gap: 32px;
            .textfields-48px{
                display: flex;
                flex-direction: column;
                gap: 12px;
            }
            .accordion-footer{
                display: flex;
                justify-content: right;
            }
        }
        &.active{
                
            .accordion-body{
                display: flex;
            }
        } 
    }
// TABLE
    table{
        background-color: white;
        border-radius: 16px;
        width: 100%;
        border: none;
        overflow: hidden;
        thead{
            border-bottom: 1px solid #E9F0FC;
            color: #5E646F;
            text-align: left;
        }
        tbody{
            tr{
                &:hover{
                    background-color: rgba($color: v.$light_grey, $alpha: .2) ;
                }
            }
        }
        td, th{
            padding-bottom: 8px;
            padding-top: 8px;

            span{
                padding: 4px;
                height: 28px;
                border-radius: 4px;
                border: 1.5px solid #9B9B9B;
                &.yellow{
                    border-color: #EBB249;
                }
                &.green{
                    border-color: #40CA77;
                }
            }

            &.action{
                cursor: pointer;
                color: v.$ocean_blue;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        tr{
            td:first-of-type, th:first-of-type{
                padding-left: 16px;
            }
            th{
                padding-top: 16px;
            }
            &:last-of-type td{
                padding-bottom: 16px;
            }
        }
        
    }
    .paginate-container{
        display: flex;
        justify-content: flex-end;
        .paginate{
            display: flex;
            gap: 8px;
            align-items: center;
            .page, .page-btn{
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                box-sizing: border-box;
            }
            .ellipsis, .page{
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                padding: 16px;
                color: v.$black;
            }
            .page{
                // width: 40px;
                height: 40px;
                border: 1px solid v.$white;
                border-radius: 12px;
                &.active{
                    background: v.$white;
                }
            }
            .page-btn{
                width: 20px;
                height: 20px;
            }
            .page-prev i{
                content: url('../../utilities/icons/arrowdown-dark.svg');
                transform: rotateZ(90deg);
            }
            .page-next i{
                content: url('../../utilities/icons/arrowdown-dark.svg');
                transform: rotateZ(-90deg);
            }
            .page-first i{
                content: url('../../utilities/icons/arrowdown-dark.svg');
                transform: rotateZ(90deg);
            }
            .page-last i{
                content: url('../../utilities/icons/arrowdown-dark.svg');
                transform: rotateZ(-90deg);
            }
        }
    }
}