@use '../utilities/variables' as v;

.textfields-48px{
    label{
        display: block;
        padding-bottom: 8px;
        color: v.$dark_blue;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
    }
    input, .input, select{
        box-sizing: border-box;
        width: 100%;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #D6D9DD;
        background-color: #FFF;
        color: v.$dark_blue;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            background-color: #fff;
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
        &::placeholder{
            color: #D6D9DD;
        }
    }
    select:invalid { color: #D6D9DD; 
        option{
            color: v.$dark_blue;
        }
    }
}
.checkbox{
    display: flex;
    align-items: center;
    cursor: pointer;
    input[type=checkbox]{
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        margin: 0;
        cursor: pointer;
        margin-right: 10px;
        margin-left: 2px;
        width: 20px;
        height: 20px;
        border: 1.5px solid #5E646F;
        border-radius: 4px;
        display: flex;
        &::before{
            content: url('../utilities/icons/checked.svg');
            // transform: translate(-10px, -10px);
            width: 11px;
            height: 8px;
            margin-left: 2.5px;
            margin-top: 1.5px;
            transform: scale(0);
        }
        &:checked::before{
            transform: scale(1);
        }
    }
}

.button-48, .button-48px{
    cursor: pointer;
    height: 48px;
    box-sizing: border-box;
    background-color: v.$ocean_blue;
    color: v.$white;
    // display: flex;
    display: inline-flex;
    // width: 100%;
    padding: 16px 50px;
    white-space: nowrap;
    flex-wrap: nowrap;
    gap: 8px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    border: none;
    text-decoration: none;
    font-size: 16px;
    &:hover{
        box-shadow: 0px 0px 20px 0px #001B424D;
    }
    &:active{
        background-color: v.$dark_blue;
    }
    &:disabled{
        background-color: #E9F0FC!important;
        box-shadow: none;
    }
    
    &.outline{
        color: v.$ocean_blue;
        background: none;
        border: 1px solid v.$ocean_blue;
        &:hover{
            box-shadow: none;
            background: rgba($color: v.$ocean_blue, $alpha: .2);
        }
        &:active{
            color: v.$white;
            background: v.$ocean_blue;
        }
        &:disabled{
            border: none;
            color: v.$white;
            background-color: #E9F0FC!important;
            
        }
    }
}

.switch{
    box-sizing: border-box;
    width: 100%;
    // min-width: 240px;
    max-width: 100%;
    border: 1px solid v.$light_blue;
    border-radius: 16px;
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    z-index: 0;
    .label{
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
    }
    label{
        position: relative;
        width: 32px;
        min-width: 32px;
        max-width: 32px;
        height: 20px;
        z-index: 0;
        .switch-slider{
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #BDBDBD33;
            border-radius: 10px;
            -webkit-transition: .4s;
            transition: .4s;
            z-index: 0;
            &::before{
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                left: 2px;
                bottom: 2px;
                border-radius: 8px;
                background: #FFFFFF;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 4px 2px rgba(0, 0, 0, 0.04);
                -webkit-transition: .4s;
                transition: .4s;
            }
        }
        input{
            opacity: 0;
            width: 0;
            height: 0;
            &:checked + .switch-slider{
                background: v.$green;
                &::before{
                    -webkit-transform: translateX(12px);
                    -ms-transform: translateX(12px);
                    transform: translateX(12px);
                }
            }
            &:focus + .switch-slider {
                outline: none;
            }
        }
    }
}