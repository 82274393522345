@use '../../utilities/variables' as v;

#admin{
    display: grid;
    grid-template-columns: 256px auto;
    grid-template-rows: 72px auto;
    &.template-rag-mirsot{
        grid-template-areas: 
            "header header"
            "menu content";
    }
    &.template-rag-zakyat{
        grid-template-areas: 
            "header header"
            "content content";
    }
    gap: 32px;
    min-height: 100vh;
    height: 100%;
    background-color: #F2F4F5;
    #adminheader{
        grid-area: header;
    }
    #adminmenu{
        grid-area: menu;
    }
    #content{
        margin-right: 32px;
        grid-area: content;
        margin-bottom: 112px;
    }
    &.template-rag-zakyat #content{
        margin-left: 32px;
    }   
    .blocks{
        display: flex;
        flex-direction: column;
        gap: 64px;
        
    }
    


    hr{
        margin: 48px 0;
        background: v.$white;
        height: 1px;
        border: none;
    }

}