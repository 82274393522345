@use '../../utilities/variables' as v;

.loading{
    display: flex;
    padding-top: 64px;
    .lds-ring {
        margin: auto;
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 6px solid v.$dark_blue;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: v.$dark_blue transparent transparent transparent;
            &:nth-child(1){
                animation-delay: -0.45s;
            }
            &:nth-child(2){
                animation-delay: -0.3s;
            }
            &:nth-child(3){
                animation-delay: -0.15s;
            }
        }
    }
    &.sm{
        padding: 0;
        .lds-ring{
            width: 20px;
            height: 20px;
            div{
                width: 16px;
                height: 16px;
                margin: 2px;
                border: 2px solid v.$white;
                border-color: v.$white transparent transparent transparent;
            }
        }
    }
    &.md{
        padding: 0;
        .lds-ring{
            width: 40px;
            height: 40px;
            div{
                width: 32px;
                height: 32px;
                margin: 4px;
                border: 2px solid v.$white;
                border-color: v.$white transparent transparent transparent;
            }
        }
    }
    &.black{
        .lds-ring {
            div {
                border-color: v.$dark_blue transparent transparent transparent;
            }
        }
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
      
}