@use '../../../utilities/variables' as v;
.block{
    display: flex;
    flex-direction: column;
    gap: 32px;
    .block-title{
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 0em;
        color: v.$black;
    }
    .block-content{
        background-color: v.$white;
        border-radius: 16px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 56px;
        .block-subtitle{
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: v.$black;
            padding-bottom: 24px;
        }
        .block-footer{
            display: flex;
            justify-content: flex-end;
            gap: 24px;
        }
    }
}