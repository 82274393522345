@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    font-family: 'Inter', sans-serif;

}
body{
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}
.whatsapp-icon{
    content: url('./utilities/icons/whatsapp.svg');
}
.facebook-icon{
    content: url('./utilities/icons/facebook.svg');
}
.instagram-icon{
    content: url('./utilities/icons/instagram.svg');
}

.iconcard{
    .image-container{
        width: 40px;
        height: 40px;
        padding: 20px;
        border-radius: 16px;
        background: rgba($color: #1C6BDB, $alpha: .15);
        margin-bottom: 8px;
    }
}


