@use '../../utilities/variables' as v;
.error{
    color: v.$error;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}
#cabinet{
    .blocks{
        display: flex;
        flex-direction: column;
        gap: 32px;
        .top{
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
        .block{
            background-color: v.$white;
            border-radius: 16px;
            padding: 32px;
            &.accordion{
                
                .accordion-header{
                    display: flex;
                    justify-content: space-between;
                    gap: 8px;
                    align-items: center;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    color: #0E0E0E;

                    cursor: pointer;
                    span{
                        display: block;
                        transition: transform .2s linear;
                        height: 32px;
                        content: url('../../utilities/icons/arrowdown-dark.svg');
                    }
                }
                .accordion-body{
                    display: none;
                    flex-direction: column;
                    gap: 32px;
                    .textfields-48px{
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                    }
                    .accordion-footer{
                        display: flex;
                        justify-content: right;
                    }
                }
                &.active .accordion-body{
                    display: flex;
                }
            }
        }
        .table-block{
            .table-title{
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                padding-bottom: 24px;
            }
            table{
                padding: 16px;
                border-radius: 16px;
                background-color: v.$white;
                width: 100%;
                tbody{
                    gap: 16px; 
                    tr{
                        td{
                            padding-top: 16px;
                            &:last-child{
                                color: v.$ocean_blue;
                                cursor: pointer;
                                &:hover{
                                    text-decoration:underline;
                                }
                            }
                        }
                    }
                }
                tr{
                    table-layout: fixed;
                    width: 100%;
                    td, th{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: v.$black;
                        display: table-cell;
                        text-align: left;
                        &:first-child{
                            width: 40px;
                        }
                        &:last-child{
                            width: 120px;
                        }
                    }
                    th{
                        color: v.$dark_grey;
                        padding-bottom: 16px;
                        border-bottom: 1px solid #F2F4F5;
                    }
                }
            }
        }
    }
}