@use '../../utilities/variables' as v;
#dashboard{

    .dropdown{
        position: relative;
        .dropbtn{
            padding: 0;
            margin: 0;
            border: 0;
            background: none;
            cursor: pointer;
            display: flex;
            gap: 8px;
            &::after{
                transition: transform .2s linear;
                height: 20px;
                content: url('../../utilities/icons/arrowdown-dark.svg');
            }
        }
        .dropcontent{
            box-sizing: border-box;
            list-style: none;
            position: absolute;
            cursor: pointer;
            color: v.$dark_blue;
            display: none;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 16px;
            // width: 196px;
            // height: 192px;
            right: 0;
            top: 100%;
            z-index: 100;
            width: 100%;
            /* Color / white */
            
            background: v.$white;
            border-radius: 8px;
            li{
                cursor: pointer;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                padding: 16px 0;
                border-bottom: 1px solid v.$light_blue;
                &:hover{
                    font-weight: 600;
                }
            }
        }
        .dropbtn:focus + .dropcontent{
            display: block;
        }
        .dropbtn:focus::after{
            transform: rotateX(180deg);
        }
    }
}