@use '../../utilities/variables' as v;

.modal{
    // display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: .15);
    display: flex;
    z-index: 100;
    &.hidden{
        display: none;
    }
    .modal-body{
        z-index: 1000;
        margin: auto;
        background-color: v.$white;
        padding: 36px 40px;
        border-radius: 16px;
        overflow: hidden;
        box-sizing: border-box;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08);
        display: flex;
        flex-direction: column;
        gap: 24px;
        &.alert{
            width: 550px;
        }
        &.action{
            width: 470px;
        }
        &.modal-loading{
            .lds-ring {
                position: relative;
                width: 24px;
                height: 24px;
                animation: lds-ring 2s linear infinite;
                content: url('../../utilities/icons/LoadingSpinner\ 1.svg');
            }
            @keyframes lds-ring {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }
        .modal-content{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 32px;
            .modal-icon{
                padding: 14px;
                border-radius: 8px;
                display: flex;
                box-sizing: border-box;
                align-self: flex-start;
                i{
                    width: 28px;
                    height: 28px;
                    aspect-ratio: 1/1;
                    object-fit: contain;
                    object-position: center;
                }
                &.success{
                    background: v.$light_blue;
                    i{content: url('../../utilities/icons/success.svg');}
                }
                &.error{
                    background: rgba(236, 93, 93, 0.1);
                    i{content: url('../../utilities/icons/error.svg');}
                }
            }
            .modal-text{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                .modal-header{
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;                    
                    color: v.$black;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    gap: 24px;
                    .modal-close-btn{
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                        content: url('../../utilities/icons/close-black.svg');
                    }
                }
                .modal-message{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: v.$dark_grey;
                    a{
                        text-decoration: none;
                        color: v.$ocean_blue;
                        font-weight: 500;
                    }
                }
            }
        }
        .modal-footer{
            display: flex;
            flex-direction: row;
            gap: 24px;
            .button-48{
                flex: 1;
                padding-left: 32px;
                padding-right: 32px;
            }
        }
    }
}