.bg-darkblue{
    background-color: #0B1832;
}
.bg-lightblue{
    background-color: #E9F0FC;
}
.bg-oceanblue{
    background-color: #1C6BDB;
}
.bg-white{
    background-color: #ffffff;
}


.color-white{
    color: #fff;
}
.color-grey {
    color: #D6D9DD;
}
.color-lightgrey {
    color: #D6D9DD;
}
.color-black {
    color: #0E0E0E;
}
.color-oceanblue{
    color: #1C6BDB;
}
.color-darkblue{
    color: #0B1832;
}
.color-darkgrey{
    color: #5E646F;
}
.color-lightblue{
    color: #E9F0FC;
}
.color-yellow{
    color: #FFCB46;
}