@use '../../utilities/variables' as v;
@import '../../utilities/adaptive';

#login, #reset,#choose-company{
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    display: flex;
    form{
        box-sizing: border-box;
        margin: auto;
        padding: 80px;
        border-radius: 32px;
        .textfield-container{
            box-sizing: border-box;
            margin-top: 24px;
            margin-bottom: 10px;
            @include _lg{
                min-width: 416px;
            }
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;
            input{
                box-sizing: border-box;
                width: 100%;
                border-radius: 16px;
            }
        }
        .more{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 26px 0;
            height: 24px;
        }
        .errors{
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #DD4141;
        }
        a{
            text-decoration: none;
            color: initial;
        }
        button{
            box-sizing: border-box;
            width: 100%;
            text-align: center;
            border-radius: 16px;
            background: v.$black;
            color: v.$white;
        }
    }
}
#reset{
    form{
        @include _lg{
            max-width: 60%;
        }
    }
}

#choose-company{
    form{
        display: flex;
        flex-direction: column;
        gap: 48px;
        .companies{
            display: flex;
            gap: 40px;
            .company-btn{
                box-sizing: border-box;
                padding: 32px;
                border: solid 2px v.$light_grey;
                border-radius: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 260px;
                cursor: pointer;
                &:hover{
                    border-color: v.$admin_bg;
                    background: v.$admin_bg;
                }
            }
        }
    }
}