@import "../utilities/adaptive";

.d-flex{display: flex; align-items: flex-start;}
.flex-column{flex-direction: column;}
.flex-row{flex-direction: row;}
.row-3{
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    &>.col{
        width: calc((100%/3) - 32px);
    }
}
.row-2{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 32px;
    &>.col{
        width: calc(50% - 32px);
    }
}
.row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.gap-1{
    gap: 8px;
}
.gap-2{
    gap: 16px;
}
.gap-3{
    gap: 24px;
}
.gap-4{
    gap: 32px;
}

.row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .col{
        width: 25%;
    }
}
.row-1{
    display: flex;
    flex-direction: column;
}

.flex-lg-nowrap{
    @include _lg{
        flex-wrap: nowrap;
        overflow-x: visible;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.flex-lg-column-reverse{
    @include _lg{
        flex-direction: column-reverse;
    }
}
.flex-xl-column-reverse{
    @include _xl{
        flex-direction: column-reverse;
    }
}
.row-lg-2{
    @include _lg{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        &>.col{
            max-width: calc((100% - 32px)/2);
            min-width: calc((100% - 32px)/2);
            width: calc((100% - 32px)/2);
        }
    }
}

.row-lg-3{
    @include _lg{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 32px;
        &>.col{
            max-width: calc(100%/3 - 32px);
            min-width: calc(100%/3 - 32px);
            width: calc(100%/3 - 32px);
        }
    }
}
.row-lg-4{
    @include _lg{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 32px;
        &>.col{
            width: calc((100% - 96px)/4)
            // max-width: calc(25% - 32px);
            // min-width: calc(25% - 32px);
            // width: calc(25% - 32px);
        }
    }
}

.container{
    box-sizing: border-box;
    width: calc(100% - 32px);
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    @include _lg{
        width: 960px;
        margin: 0 auto;
    }
    @include _xl{
        width: 1120px;
        margin: 0 auto;
    }
}
.justify-content-between{
    justify-content: space-between;
}
.align-items-center{
    align-items: center;
}