// colors
$dark_blue: #0B1832;
$ocean_blue: #1C6BDB;
$light_blue: #E9F0FC;
$yellow: #FFCB46;
$zakyat-yellow: #EBB249;
$black: #0E0E0E;
$zakyatblack: #141414;
$white: #FFFFFF;
$light_grey: #D6D9DD;
$dark_grey: #5E646F;
$green: #27AE60;
$success: #00C851;
$error: #DD4141;
$admin_bg: #F2F4F5;