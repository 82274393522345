@use '../../../utilities/variables' as v;

.progress-bar{
    width: 100%;
    background: v.$light_grey;
    height: 16px;
    min-height: 16px;
    border-radius: 8px;
    overflow: hidden;
    &>div{
        background: v.$green;
        height: 100%;
        border-radius: 8px;
    }
}