@use '../../../utilities/variables' as v;
.singletext-edit{
    display: flex;
    flex-direction: column;
    gap: 32px;
    .edit-subblock{
        label{
            display: block;
            color: v.$dark_blue;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 12px;
        }
        input{
            width: 100%;
            height: auto;
            border: none;
            background-color: v.$admin_bg;
            padding: 19px 16px;
            border-radius: 8px;
            box-sizing: border-box;
            width: 100%;
            resize: none;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            &:focus, &:focus-visible{
                outline: 1px solid v.$ocean_blue;
            }
        }
        .input-group{
            display: flex;
            .input-group-text{
                border-bottom-left-radius: 8px;
                border-top-left-radius: 8px;
                height: auto;
                border: none;
                background-color: v.$light_grey;
                padding: 19px 16px;
                box-sizing: border-box;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
            }
            input{
                flex-grow: 1;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }
}
.text-edit{
    display: flex;
    flex-direction: column;
    gap: 32px;
    .edit-subblock{
        .lang{
            color: v.$dark_blue;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 12px;
        }
        .textarea{
            width: 100%;
            height: auto;
            border: none;
            background-color: v.$admin_bg;
            padding: 19px 16px;
            border-radius: 8px;
            box-sizing: border-box;
            textarea{
                width: 100%;
                resize: none;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                padding: 0;
                background: none;
                border: none;
                &:focus, &:focus-visible{
                    outline: none;
                }
            }
            &.focus{
                outline: 1px solid v.$ocean_blue;
            }
            .limit{
                padding-top: 16px;
                display: flex;
                justify-content: flex-end;
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                color: v.$dark_grey;
            }
        }
    }
}
.card-edit{
    .edit-cards{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .edit-cardsub{
            width: 30%;
            padding: 10px;
            background-color: white;
            border-radius: 10px;
            textarea{
                border: none;
                background: none;
                width: 100%;
                height: 100%;
                resize: none;
                height: auto;
                
            }
        }
    }
}
.image-edit{
    .img-container{
        max-width: 363px;
        width: 363px;
        aspect-ratio: 363 / 232;
        overflow: hidden;
        border-radius: 16px;
        position: relative;
        box-sizing: border-box;
        
        .img-overlay,.noimg{
            box-sizing: border-box;
            position: absolute;
            display: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($color: #000000, $alpha: .6);
            padding: 15px 18px;
            .actions{
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 40px;
                border: 2px dashed v.$white;
                border-radius: 16px; 
                width: 100%;
                height: 100%;
                .action-btn{
                    box-sizing: border-box;
                    cursor: pointer;
                    display: flex;
                    width: 40px;
                    height: 40px;
                    background: rgba($color: v.$white, $alpha: .4);
                    border: 1px solid v.$white;
                    border-radius: 8px;
                    input{
                        width: 0;
                        height: 0;
                        opacity: 0;
                    }
                    i{
                        width: 24px;
                        height: 24px;
                        margin: auto;
                    }
                    &.upload{
                        i{
                            content: url('../../../utilities/icons/Upload.svg');
                        }
                    }
                    &.delete{
                        i{
                            content: url('../../../utilities/icons/TrashDelete.svg');
                        }
                    }
                }
            }
        }
        
        &:hover .img-overlay{
            display: block;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &.icon{
            width: 120px;
            aspect-ratio: 1 / 1;
            padding: 30px;
        }
        .noimg{
            display: block;
            border: none;
            background: v.$light_grey;
            .actions{
                border: none;
                .action-btn{
                    box-sizing: border-box;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    width: auto;
                    gap: 12px;
                    height: auto;
                    background: none;
                    border: none;
                    // border-radius: 8px;
                    i{
                        width: 32px;
                        height: 32px;
                        margin: auto;
                    }
                    span{
                        display: none;
                        text-align: center;
                        color: v.$white;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 16px;
                    }
                    &.upload{
                        i{
                            content: url('../../../utilities/icons/Picture.svg');
                        }
                    }
                }
            }
        }
        &:hover .noimg{
            background: v.$dark_grey;
            .actions{
                border: 2px dashed v.$white;
                .action-btn{
                    span{
                        display: block;
                    }
                }
            }
        }


        &.staffImage{
            background-color: v.$light_blue;
            max-width: 320px;
            width: 320px;
            aspect-ratio: 1 / 1;
        }
    }
}

.carousell{
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}