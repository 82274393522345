@use "../utilities/variables" as v;
@import '../utilities/adaptive';

.icon-card{
    color: v.$dark_blue;
    &.color-lightblue{
        color: v.$light_blue;
    }
    .icon-cardimage{
        padding: 16px;
        border-radius: 16px;
        width: 48px;
        height: 48px;  
        img{
            aspect-ratio: 1 / 1;
            width: 100%;
            object-fit: contain;
            object-position: center;
        }
        margin-bottom: 8px;
    }
    .icon-cardtitle{
        font-weight: 500;
        font-size: 16px;
        line-height: 34px;   
        a{
            text-decoration: none;
            color: inherit;
        }     
        @include _lg{
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
        }
    }
    &.directions{
        .icon-cardimage{
            background-color: rgba($color: v.$ocean_blue, $alpha: .15);
        }
    }
    &.services{
        .icon-cardimage{
            background-color: rgba($color: v.$yellow, $alpha: .15);
        }
    }
}