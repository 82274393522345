@use '../../utilities/variables' as v;
@import '../../utilities/adaptive';

#adminheader{
    background-color: #0B1832;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:  0 32px;
    .brand{
        display: flex;
        .logotype{
            margin: auto;
            content: url('../../utilities/icons/rag-mirsot-logo.svg');
        }
    }
    .buttons{
        display: flex;
        gap: 24px;
        height: 100%;
        align-items: center;
        .button-48px{
            border-radius: 16px;
        }
        .cabinet{
            background: none;
            border: 2px solid #1C6BDB;
        }

        .dropdown{
            position: relative;
            .dropbtn{
                display: flex;
                &::after{
                    transition: transform .2s linear;
                    height: 20px;
                    content: url('../../utilities/icons/arrowdown.svg');
                }
            }
            .dropcontent{
                z-index: 100;
                // width: 100%;
                min-width: 100%;
                margin: 0;
                box-sizing: border-box;
                cursor: pointer;
                color: v.$dark_blue;
                display: none;
                overflow: hidden;
                flex-direction: column;
                align-items: flex-start;
                padding: 0 16px;
                position: absolute;
                // width: 196px;
                // height: 192px;
                right: 0;
                top: 100%;
                /* Color / white */
                
                background: v.$white;
                border-radius: 8px;
                li{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 16px;
                    border-bottom: 1px solid v.$light_blue;
                    a{
                        padding: 16px 0;
                    }
                    &:last-of-type{
                        border: none;
                    }
                }
            }
            &:hover .dropcontent{
                display: block;
            }
            &:hover .dropbtn{
                &::after{
                    transform: rotateX(180deg);
                    // content: url('../../../utilities/icons/arrowup.svg');
                }
            }
            &.active .dropcontent{
                display: block;
            }
            &.active .dropbtn{
                &::after{
                    transform: rotateX(180deg);
                    // content: url('../../../utilities/icons/arrowup.svg');
                }
            }
            
        }

        #language{
            
            height: 100%;
            display: flex;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            .dropbtn{
                text-decoration: underline;
                color: v.$white;
                cursor: pointer;
                margin: auto 0;
                display: flex;
                align-items: center;
                gap: 8px;
                flex-wrap: nowrap;
                // padding: 8px;
                height: 100%;
                &::after{
                    margin-left: 0;
                }
            }
            li{
                display: flex;
                align-items: center;
                gap: 8px;
                flex-wrap: nowrap;
                &.hidden{
                    display: none;
                }
            }
        
        }
    }


    &.rag-zakyat{
        background-color: v.$zakyatblack;
        .brand{
            .logotype{
                content: url('../../utilities/icons/rag-zakyat-logo.svg');
            }
        }
        .buttons{
            .button-48px{
                border-radius: 4px;
                height: 40px;
                padding: 0px 16px;
            }
            .change-company{
                background: none;
                border: 2px solid v.$white;
            }
            #language{
                .dropbtn{
                    color: v.$zakyat-yellow;
                }
            }

            .logout{
                background: v.$white;
                border: 2px solid v.$white;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: v.$zakyatblack;
            }
        }
    }
}
