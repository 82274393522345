@use '../../../utilities/variables' as v;

#about{
    .tabs{
        display: flex;
        flex-wrap: wrap;
        padding-top: 12px;
        padding-bottom: 32px;
        .tab{
            cursor: pointer;
            padding: 20px;
            // padding-top: 0;
            border-bottom: 1px solid v.$dark_grey;
            color: v.$dark_grey;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            &.active{
                border-bottom-color: v.$black;
                color: v.$black;
                font-weight: 600; 
            }
        }
    }
    .tab-contents{
        .tab-content{
            display: none;
            &.active{
                display: flex;
                flex-direction: column;
                gap: 32px;
            }
        }
    }
}